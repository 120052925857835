import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Carousel from 'react-bootstrap/Carousel';

import banner1 from '../assets/images/banners/1.jpg';
import banner2 from '../assets/images/banners/2.jpg';
import banner3 from '../assets/images/banners/3.png';
import banner4 from '../assets/images/banners/4.jpg';
//import banner5 from '../assets/images/banners/5.jpg';

import bannerMob1 from '../assets/images/banners/mobile/1.jpg';
import bannerMob2 from '../assets/images/banners/mobile/2.jpg';
import bannerMob3 from '../assets/images/banners/mobile/3.jpg';
import bannerMob4 from '../assets/images/banners/mobile/4.jpg';

import CpaRegistrationFormModal from './CpaRegistrationFormModal';


const BannerNew = props => {
  const [cpaRegModalShow, setCpaRegModalShow] = React.useState(false);

  return (
    <>
      <section className='banner_sec new'>
        <div className='banner_slider desk_view'>
          <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                <a href="#."
                   onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={banner1}
                    alt="first slide"
                  />     
                </a>          
              </Carousel.Item>
              <Carousel.Item>
                <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={banner2}
                    alt="second slide"
                  /> 
                </a>              
              </Carousel.Item>
              <Carousel.Item>
              <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={banner3}
                    alt="third slide"
                  />   
                </a>            
              </Carousel.Item>
              <Carousel.Item>
              <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                <img
                  className="d-block w-100"
                  src={banner4}
                  alt="forth slide"
                />         
                </a>      
              </Carousel.Item>
              {/* <Carousel.Item>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" 
                target='_blank'>
                <img
                  className="d-block w-100"
                  src={banner5}
                  alt="forth slide"
                />         
                </a>      
              </Carousel.Item> */}
            </Carousel>
        </div>
        <div className='banner_slider mob_view'>
          <Carousel data-bs-theme="dark" id='carouselExampleAutoplaying'>
              <Carousel.Item>
                <a href="#."
                   onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={bannerMob1}
                    alt="first slide"
                  />     
                </a>          
              </Carousel.Item>
              <Carousel.Item>
                <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={bannerMob2}
                    alt="second slide"
                  /> 
                </a>              
              </Carousel.Item>
              <Carousel.Item>
              <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                  <img
                    className="d-block w-100"
                    src={bannerMob3}
                    alt="third slide"
                  />   
                </a>            
              </Carousel.Item>
              <Carousel.Item>
              <a href="#." 
                onClick={() => setCpaRegModalShow(true)}>
                <img
                  className="d-block w-100"
                  src={bannerMob4}
                  alt="forth slide"
                />         
                </a>      
              </Carousel.Item>
            </Carousel>
        </div>
        <CpaRegistrationFormModal
          show={cpaRegModalShow}
          onHide={() => setCpaRegModalShow(false)}
      />
      </section>      
    </>
  );
};

export default BannerNew;