export const citys = () => {
  return [
    {
      "id": "1",
      "name": "Achalpur"
    },
    {
      "id": "2",
      "name": "Aheri"
    },
    {
      "id": "3",
      "name": "Ahmednagar"
    },
    {
      "id": "4",
      "name": "Ahmedpur"
    },
    {
      "id": "5",
      "name": "Ajara"
    },
    {
      "id": "6",
      "name": "Akkalkot"
    },
    {
      "id": "7",
      "name": "Akkalkuva"
    },
    {
      "id": "8",
      "name": "Akluj"
    },
    {
      "id": "9",
      "name": "Akola"
    },
    {
      "id": "10",
      "name": "Akole"
    },
    {
      "id": "11",
      "name": "Akot"
    },
    {
      "id": "12",
      "name": "Alibag"
    },
    {
      "id": "13",
      "name": "Amalner"
    },
    {
      "id": "14",
      "name": "Ambad"
    },
    {
      "id": "15",
      "name": "Ambajogai"
    },
    {
      "id": "16",
      "name": "Ambegaon"
    },
    {
      "id": "17",
      "name": "Ambernath"
    },
    {
      "id": "18",
      "name": "Ambewadgaon"
    },
    {
      "id": "19",
      "name": "Amgaon"
    },
    {
      "id": "20",
      "name": "Amravati"
    },
    {
      "id": "21",
      "name": "Anjaneri"
    },
    {
      "id": "22",
      "name": "Anjangaon"
    },
    {
      "id": "23",
      "name": "Ardhapur"
    },
    {
      "id": "24",
      "name": "Arjuni"
    },
    {
      "id": "25",
      "name": "Armori"
    },
    {
      "id": "26",
      "name": "Arvi"
    },
    {
      "id": "27",
      "name": "Ashti"
    },
    {
      "id": "28",
      "name": "Atpadi"
    },
    {
      "id": "29",
      "name": "Aundha Nagnath"
    },
    {
      "id": "30",
      "name": "Aurangabad"
    },
    {
      "id": "31",
      "name": "Ausa"
    },
    {
      "id": "32",
      "name": "Babhulgaon"
    },
    {
      "id": "33",
      "name": "Badnapur"
    },
    {
      "id": "34",
      "name": "Balapur"
    },
    {
      "id": "35",
      "name": "Balharshah"
    },
    {
      "id": "36",
      "name": "Baramati"
    },
    {
      "id": "37",
      "name": "Barshi"
    },
    {
      "id": "38",
      "name": "Barsi Takli"
    },
    {
      "id": "39",
      "name": "Basmat"
    },
    {
      "id": "40",
      "name": "Bavada"
    },
    {
      "id": "41",
      "name": "Beed"
    },
    {
      "id": "42",
      "name": "Bhadgaon"
    },
    {
      "id": "43",
      "name": "Bhadravati"
    },
    {
      "id": "44",
      "name": "Bhamragarh"
    },
    {
      "id": "45",
      "name": "Bhandara"
    },
    {
      "id": "46",
      "name": "Bhatkuli"
    },
    {
      "id": "47",
      "name": "Bhavaninagar"
    },
    {
      "id": "48",
      "name": "Bhikarkheda"
    },
    {
      "id": "49",
      "name": "Bhiwandi"
    },
    {
      "id": "50",
      "name": "Bhiwapur"
    },
    {
      "id": "51",
      "name": "Bhokar"
    },
    {
      "id": "52",
      "name": "Bhokardan"
    },
    {
      "id": "53",
      "name": "Bhoom"
    },
    {
      "id": "54",
      "name": "Bhusawal"
    },
    {
      "id": "55",
      "name": "Bhuye"
    },
    {
      "id": "56",
      "name": "Biloli"
    },
    {
      "id": "57",
      "name": "Bodwad"
    },
    {
      "id": "58",
      "name": "Buldhana"
    },
    {
      "id": "59",
      "name": "Chakur"
    },
    {
      "id": "60",
      "name": "Chalisgaon"
    },
    {
      "id": "61",
      "name": "Chamorshi"
    },
    {
      "id": "62",
      "name": "Chandgad"
    },
    {
      "id": "63",
      "name": "Chandrapur"
    },
    {
      "id": "64",
      "name": "Chandsaili"
    },
    {
      "id": "65",
      "name": "Chandur"
    },
    {
      "id": "66",
      "name": "Chandur Bazar"
    },
    {
      "id": "67",
      "name": "Chandur Railway"
    },
    {
      "id": "68",
      "name": "Chandwad"
    },
    {
      "id": "69",
      "name": "Chikhaldara"
    },
    {
      "id": "70",
      "name": "Chikhli"
    },
    {
      "id": "71",
      "name": "Chimur"
    },
    {
      "id": "72",
      "name": "Chiplun"
    },
    {
      "id": "73",
      "name": "Chopda"
    },
    {
      "id": "74",
      "name": "Dahanu"
    },
    {
      "id": "75",
      "name": "Dahiwadi"
    },
    {
      "id": "76",
      "name": "Dapoli"
    },
    {
      "id": "77",
      "name": "Darwha"
    },
    {
      "id": "78",
      "name": "Daryapur Banosa"
    },
    {
      "id": "79",
      "name": "Daund"
    },
    {
      "id": "80",
      "name": "Deaulgaon Raja"
    },
    {
      "id": "81",
      "name": "Degloor"
    },
    {
      "id": "82",
      "name": "Deoni"
    },
    {
      "id": "83",
      "name": "Desaiganj"
    },
    {
      "id": "84",
      "name": "Deulgaon Mahi"
    },
    {
      "id": "85",
      "name": "Devgad"
    },
    {
      "id": "86",
      "name": "Dhadgaon"
    },
    {
      "id": "87",
      "name": "Dhamangaon"
    },
    {
      "id": "88",
      "name": "Dharangaon"
    },
    {
      "id": "89",
      "name": "Dharmabad"
    },
    {
      "id": "90",
      "name": "Dharni"
    },
    {
      "id": "91",
      "name": "Dharur"
    },
    {
      "id": "92",
      "name": "Dholagarwadi"
    },
    {
      "id": "93",
      "name": "Dhondewadi"
    },
    {
      "id": "94",
      "name": "Dhule"
    },
    {
      "id": "95",
      "name": "Digras"
    },
    {
      "id": "96",
      "name": "Dodamarg"
    },
    {
      "id": "97",
      "name": "Dombivli"
    },
    {
      "id": "98",
      "name": "Erandol"
    },
    {
      "id": "99",
      "name": "Etapalli"
    },
    {
      "id": "100",
      "name": "Gadchiroli"
    },
    {
      "id": "101",
      "name": "Gadhinglaj"
    },
    {
      "id": "102",
      "name": "Gaganbavada"
    },
    {
      "id": "103",
      "name": "Gaganbawda"
    },
    {
      "id": "104",
      "name": "Gangakhed"
    },
    {
      "id": "105",
      "name": "Georai"
    },
    {
      "id": "106",
      "name": "Ghansawangi"
    },
    {
      "id": "107",
      "name": "Ghatanji"
    },
    {
      "id": "108",
      "name": "Ghera Sudhagad"
    },
    {
      "id": "109",
      "name": "Gherapurandhar"
    },
    {
      "id": "110",
      "name": "Godhani"
    },
    {
      "id": "111",
      "name": "Gondia"
    },
    {
      "id": "112",
      "name": "Gondpipri"
    },
    {
      "id": "113",
      "name": "Goregaon"
    },
    {
      "id": "114",
      "name": "Gorewasti"
    },
    {
      "id": "115",
      "name": "Guhagar"
    },
    {
      "id": "116",
      "name": "Hadgaon"
    },
    {
      "id": "117",
      "name": "Harat Kheda"
    },
    {
      "id": "118",
      "name": "Hatkanangale"
    },
    {
      "id": "119",
      "name": "Himayatnagar"
    },
    {
      "id": "120",
      "name": "Hinganghat"
    },
    {
      "id": "121",
      "name": "Hingna"
    },
    {
      "id": "122",
      "name": "Hingoli"
    },
    {
      "id": "123",
      "name": "Indapur"
    },
    {
      "id": "124",
      "name": "Jalgaon"
    },
    {
      "id": "125",
      "name": "Jalgaon Jamod"
    },
    {
      "id": "126",
      "name": "Jalkot"
    },
    {
      "id": "127",
      "name": "Jalna"
    },
    {
      "id": "128",
      "name": "Jamkhed"
    },
    {
      "id": "129",
      "name": "Jath"
    },
    {
      "id": "130",
      "name": "Javali"
    },
    {
      "id": "131",
      "name": "Jawhar"
    },
    {
      "id": "132",
      "name": "Jayakochiwadi"
    },
    {
      "id": "133",
      "name": "Jintur"
    },
    {
      "id": "134",
      "name": "Jiwati"
    },
    {
      "id": "135",
      "name": "Junnar"
    },
    {
      "id": "136",
      "name": "Kadegaon"
    },
    {
      "id": "137",
      "name": "Kagal"
    },
    {
      "id": "138",
      "name": "Kahanapur"
    },
    {
      "id": "139",
      "name": "Kahatav"
    },
    {
      "id": "140",
      "name": "Kaij"
    },
    {
      "id": "141",
      "name": "Kalamb"
    },
    {
      "id": "142",
      "name": "Kalameshwar"
    },
    {
      "id": "143",
      "name": "Kalamnuri"
    },
    {
      "id": "144",
      "name": "Kallam"
    },
    {
      "id": "145",
      "name": "Kalyan"
    },
    {
      "id": "146",
      "name": "Kamptee"
    },
    {
      "id": "147",
      "name": "Kandhar"
    },
    {
      "id": "148",
      "name": "Kankavali"
    },
    {
      "id": "149",
      "name": "Kannad"
    },
    {
      "id": "150",
      "name": "Karad"
    },
    {
      "id": "151",
      "name": "Karanja"
    },
    {
      "id": "152",
      "name": "Karanja (Ghadge)"
    },
    {
      "id": "153",
      "name": "Karanjali"
    },
    {
      "id": "154",
      "name": "Karjat"
    },
    {
      "id": "155",
      "name": "Karmala"
    },
    {
      "id": "156",
      "name": "Karmala Rural"
    },
    {
      "id": "157",
      "name": "Katol"
    },
    {
      "id": "158",
      "name": "Kavathe Mahankal"
    },
    {
      "id": "159",
      "name": "Kelapur"
    },
    {
      "id": "160",
      "name": "Khalapur"
    },
    {
      "id": "161",
      "name": "Khamgaon"
    },
    {
      "id": "162",
      "name": "Khanapur"
    },
    {
      "id": "163",
      "name": "Khandala"
    },
    {
      "id": "164",
      "name": "Khatav"
    },
    {
      "id": "165",
      "name": "Khopoli"
    },
    {
      "id": "166",
      "name": "Khuldabad"
    },
    {
      "id": "167",
      "name": "Kikali"
    },
    {
      "id": "168",
      "name": "Kinwat"
    },
    {
      "id": "169",
      "name": "Kiwat"
    },
    {
      "id": "170",
      "name": "Kodgaon Ambad"
    },
    {
      "id": "171",
      "name": "Kolgaon"
    },
    {
      "id": "172",
      "name": "Kolhapur"
    },
    {
      "id": "173",
      "name": "Kopargaon"
    },
    {
      "id": "174",
      "name": "Korchi"
    },
    {
      "id": "175",
      "name": "Koregaon"
    },
    {
      "id": "176",
      "name": "Korpana"
    },
    {
      "id": "177",
      "name": "Kudal"
    },
    {
      "id": "178",
      "name": "Kudnur"
    },
    {
      "id": "179",
      "name": "Kuhi"
    },
    {
      "id": "180",
      "name": "Kurduwadi"
    },
    {
      "id": "181",
      "name": "Kurkheda"
    },
    {
      "id": "182",
      "name": "Lakhandur"
    },
    {
      "id": "183",
      "name": "Lakhni"
    },
    {
      "id": "184",
      "name": "Lanja"
    },
    {
      "id": "185",
      "name": "Latur"
    },
    {
      "id": "186",
      "name": "Lohara"
    },
    {
      "id": "187",
      "name": "Lonar"
    },
    {
      "id": "188",
      "name": "Lonavala"
    },
    {
      "id": "189",
      "name": "Madha"
    },
    {
      "id": "190",
      "name": "Mahabaleshwar"
    },
    {
      "id": "191",
      "name": "Mahad"
    },
    {
      "id": "192",
      "name": "Mahagaon"
    },
    {
      "id": "193",
      "name": "Malegaon"
    },
    {
      "id": "194",
      "name": "Malegaon Bk"
    },
    {
      "id": "195",
      "name": "Malegaon Kannad"
    },
    {
      "id": "196",
      "name": "Malkapur"
    },
    {
      "id": "197",
      "name": "Malshiras"
    },
    {
      "id": "198",
      "name": "Malvan"
    },
    {
      "id": "199",
      "name": "Manchar"
    },
    {
      "id": "200",
      "name": "Mandangad"
    },
    {
      "id": "201",
      "name": "Mandur"
    },
    {
      "id": "202",
      "name": "Mangalwedha"
    },
    {
      "id": "203",
      "name": "Mangaon"
    },
    {
      "id": "204",
      "name": "Mangrulpir"
    },
    {
      "id": "205",
      "name": "Mantha"
    },
    {
      "id": "206",
      "name": "Manwath"
    },
    {
      "id": "207",
      "name": "Maregaon"
    },
    {
      "id": "208",
      "name": "Matheran"
    },
    {
      "id": "209",
      "name": "Mauda"
    },
    {
      "id": "210",
      "name": "Mawalgaon"
    },
    {
      "id": "211",
      "name": "Mehkar"
    },
    {
      "id": "212",
      "name": "Mhasala"
    },
    {
      "id": "213",
      "name": "Mhase"
    },
    {
      "id": "214",
      "name": "Mira Bhayandar"
    },
    {
      "id": "215",
      "name": "Miraj"
    },
    {
      "id": "216",
      "name": "Mohadi"
    },
    {
      "id": "217",
      "name": "Mohdal Bhanguda"
    },
    {
      "id": "218",
      "name": "Mohol"
    },
    {
      "id": "219",
      "name": "Mohopada"
    },
    {
      "id": "220",
      "name": "Mokhada"
    },
    {
      "id": "221",
      "name": "Morshi"
    },
    {
      "id": "222",
      "name": "Motala"
    },
    {
      "id": "223",
      "name": "Mudkhed"
    },
    {
      "id": "224",
      "name": "Mukhed"
    },
    {
      "id": "225",
      "name": "Muktainagar"
    },
    {
      "id": "226",
      "name": "Mulchera"
    },
    {
      "id": "227",
      "name": "Mulshi"
    },
    {
      "id": "228",
      "name": "Mumbai"
    },
    {
      "id": "229",
      "name": "Mumbai City"
    },
    {
      "id": "230",
      "name": "Mumbai Suburban"
    },
    {
      "id": "231",
      "name": "Mundhe Tarf Chiplun"
    },
    {
      "id": "232",
      "name": "Murbad"
    },
    {
      "id": "233",
      "name": "Murtizapur"
    },
    {
      "id": "234",
      "name": "Murud"
    },
    {
      "id": "235",
      "name": "Murud rural"
    },
    {
      "id": "236",
      "name": "Nagbhid"
    },
    {
      "id": "237",
      "name": "Nagbhir"
    },
    {
      "id": "238",
      "name": "Nagpur"
    },
    {
      "id": "239",
      "name": "Naigaon"
    },
    {
      "id": "240",
      "name": "Nala Sopara"
    },
    {
      "id": "241",
      "name": "Nanded"
    },
    {
      "id": "242",
      "name": "Nandgaon Khandeshwar"
    },
    {
      "id": "243",
      "name": "Nandura"
    },
    {
      "id": "244",
      "name": "Nandurbar"
    },
    {
      "id": "245",
      "name": "Narkhed"
    },
    {
      "id": "246",
      "name": "Nashik"
    },
    {
      "id": "247",
      "name": "Navapur"
    },
    {
      "id": "248",
      "name": "Navi Mumbai"
    },
    {
      "id": "249",
      "name": "Naygaon"
    },
    {
      "id": "250",
      "name": "Newasa"
    },
    {
      "id": "251",
      "name": "Nilanga"
    },
    {
      "id": "252",
      "name": "Niphad"
    },
    {
      "id": "253",
      "name": "Omerga"
    },
    {
      "id": "254",
      "name": "Oros"
    },
    {
      "id": "255",
      "name": "Osmanabad"
    },
    {
      "id": "256",
      "name": "Pachora"
    },
    {
      "id": "257",
      "name": "Paithan"
    },
    {
      "id": "258",
      "name": "Palghar"
    },
    {
      "id": "259",
      "name": "Palus"
    },
    {
      "id": "260",
      "name": "Pandharpur"
    },
    {
      "id": "261",
      "name": "Panhala"
    },
    {
      "id": "262",
      "name": "Panumbre Tarf Shirala"
    },
    {
      "id": "263",
      "name": "Panvel"
    },
    {
      "id": "264",
      "name": "Paranda"
    },
    {
      "id": "265",
      "name": "Paratwada"
    },
    {
      "id": "266",
      "name": "Parbhani"
    },
    {
      "id": "267",
      "name": "Parli"
    },
    {
      "id": "268",
      "name": "Parner"
    },
    {
      "id": "269",
      "name": "Parola"
    },
    {
      "id": "270",
      "name": "Parsamal"
    },
    {
      "id": "271",
      "name": "Parshivni"
    },
    {
      "id": "272",
      "name": "Partur"
    },
    {
      "id": "273",
      "name": "Pathardi"
    },
    {
      "id": "274",
      "name": "Patoda"
    },
    {
      "id": "275",
      "name": "Pauni"
    },
    {
      "id": "276",
      "name": "Peint"
    },
    {
      "id": "277",
      "name": "Pen"
    },
    {
      "id": "278",
      "name": "Peth"
    },
    {
      "id": "279",
      "name": "Peth Umri"
    },
    {
      "id": "280",
      "name": "Phaltan"
    },
    {
      "id": "281",
      "name": "Phulambri"
    },
    {
      "id": "282",
      "name": "Pimpalgaon Tarf Khed"
    },
    {
      "id": "283",
      "name": "Pimpri Ashti"
    },
    {
      "id": "284",
      "name": "Pimpri Bk.Pr.Pachora"
    },
    {
      "id": "285",
      "name": "Pimpri-Chinchwad"
    },
    {
      "id": "286",
      "name": "Poladpur"
    },
    {
      "id": "287",
      "name": "Pombhurna"
    },
    {
      "id": "288",
      "name": "Pulgaon"
    },
    {
      "id": "289",
      "name": "Pune"
    },
    {
      "id": "290",
      "name": "Purna"
    },
    {
      "id": "291",
      "name": "Pusad"
    },
    {
      "id": "292",
      "name": "Radhanagari"
    },
    {
      "id": "293",
      "name": "Rahata"
    },
    {
      "id": "294",
      "name": "Rahta Pimplas"
    },
    {
      "id": "295",
      "name": "Rahuri"
    },
    {
      "id": "296",
      "name": "Raigad"
    },
    {
      "id": "297",
      "name": "Rajapur"
    },
    {
      "id": "298",
      "name": "Rajgurunagar"
    },
    {
      "id": "299",
      "name": "Rajura"
    },
    {
      "id": "300",
      "name": "Ralegaon"
    },
    {
      "id": "301",
      "name": "Ramkheda"
    },
    {
      "id": "302",
      "name": "Ramtek"
    },
    {
      "id": "303",
      "name": "Ranala"
    },
    {
      "id": "304",
      "name": "Ranewadi"
    },
    {
      "id": "305",
      "name": "Ratnagiri"
    },
    {
      "id": "306",
      "name": "Raver"
    },
    {
      "id": "307",
      "name": "Risod"
    },
    {
      "id": "308",
      "name": "Roha"
    },
    {
      "id": "309",
      "name": "Sadak Arjuni"
    },
    {
      "id": "310",
      "name": "Sakoli"
    },
    {
      "id": "311",
      "name": "Sakri"
    },
    {
      "id": "312",
      "name": "Salekasa"
    },
    {
      "id": "313",
      "name": "Samudrapur"
    },
    {
      "id": "314",
      "name": "Sangameshwar"
    },
    {
      "id": "315",
      "name": "Sangamner"
    },
    {
      "id": "316",
      "name": "Sangli"
    },
    {
      "id": "317",
      "name": "Sangola"
    },
    {
      "id": "318",
      "name": "Sangrampur"
    },
    {
      "id": "319",
      "name": "Saoli"
    },
    {
      "id": "320",
      "name": "Saswad"
    },
    {
      "id": "321",
      "name": "Satana"
    },
    {
      "id": "322",
      "name": "Satara"
    },
    {
      "id": "323",
      "name": "Savner"
    },
    {
      "id": "324",
      "name": "Sawali"
    },
    {
      "id": "325",
      "name": "Sawantwadi"
    },
    {
      "id": "326",
      "name": "Seloo"
    },
    {
      "id": "327",
      "name": "Selu"
    },
    {
      "id": "328",
      "name": "Sengaon"
    },
    {
      "id": "329",
      "name": "Shahada"
    },
    {
      "id": "330",
      "name": "Shahade"
    },
    {
      "id": "331",
      "name": "Shahapur"
    },
    {
      "id": "332",
      "name": "Shahuwadi"
    },
    {
      "id": "333",
      "name": "Shegaon"
    },
    {
      "id": "334",
      "name": "Shekhmirewadi"
    },
    {
      "id": "335",
      "name": "Shevgaon"
    },
    {
      "id": "336",
      "name": "Shirala"
    },
    {
      "id": "337",
      "name": "Shirol"
    },
    {
      "id": "338",
      "name": "Shirpur"
    },
    {
      "id": "339",
      "name": "Shirur"
    },
    {
      "id": "340",
      "name": "Shirur Anantpal"
    },
    {
      "id": "341",
      "name": "Shirwal"
    },
    {
      "id": "342",
      "name": "Shrigonda"
    },
    {
      "id": "343",
      "name": "Shrirampur"
    },
    {
      "id": "344",
      "name": "Shrivardhan"
    },
    {
      "id": "345",
      "name": "Sillod"
    },
    {
      "id": "346",
      "name": "Sindewahi"
    },
    {
      "id": "347",
      "name": "Sindhudurg"
    },
    {
      "id": "348",
      "name": "Sindkhed Raja"
    },
    {
      "id": "349",
      "name": "Sindkheda"
    },
    {
      "id": "350",
      "name": "Sinnar"
    },
    {
      "id": "351",
      "name": "Sironcha"
    },
    {
      "id": "352",
      "name": "Sironcha Ry."
    },
    {
      "id": "353",
      "name": "Solapur"
    },
    {
      "id": "354",
      "name": "Sonpeth"
    },
    {
      "id": "355",
      "name": "Soygaon"
    },
    {
      "id": "356",
      "name": "Surgana"
    },
    {
      "id": "357",
      "name": "Takali Ambad"
    },
    {
      "id": "358",
      "name": "Talasari"
    },
    {
      "id": "359",
      "name": "Taloda"
    },
    {
      "id": "360",
      "name": "Talvat Khed"
    },
    {
      "id": "361",
      "name": "Talvat Pal"
    },
    {
      "id": "362",
      "name": "Tambale"
    },
    {
      "id": "363",
      "name": "Tasgaon"
    },
    {
      "id": "364",
      "name": "Telhara"
    },
    {
      "id": "365",
      "name": "Tembhari"
    },
    {
      "id": "366",
      "name": "Teosa"
    },
    {
      "id": "367",
      "name": "Thane"
    },
    {
      "id": "368",
      "name": "Tirora"
    },
    {
      "id": "369",
      "name": "Trimbak"
    },
    {
      "id": "370",
      "name": "Tuljapur"
    },
    {
      "id": "371",
      "name": "Tumsar"
    },
    {
      "id": "372",
      "name": "Udgir"
    },
    {
      "id": "373",
      "name": "Umarkhed"
    },
    {
      "id": "374",
      "name": "Umred"
    },
    {
      "id": "375",
      "name": "Vadgaon"
    },
    {
      "id": "376",
      "name": "Vaibhavwadi"
    },
    {
      "id": "377",
      "name": "Vaijapur"
    },
    {
      "id": "378",
      "name": "Varvand"
    },
    {
      "id": "379",
      "name": "Vasai"
    },
    {
      "id": "380",
      "name": "Velhe"
    },
    {
      "id": "381",
      "name": "Vengurla"
    },
    {
      "id": "382",
      "name": "Vikramgad"
    },
    {
      "id": "383",
      "name": "Virar"
    },
    {
      "id": "384",
      "name": "Wada"
    },
    {
      "id": "385",
      "name": "Wadhe"
    },
    {
      "id": "386",
      "name": "Wadwani"
    },
    {
      "id": "387",
      "name": "Wai"
    },
    {
      "id": "388",
      "name": "Walwa"
    },
    {
      "id": "389",
      "name": "Wani"
    },
    {
      "id": "390",
      "name": "Wardha"
    },
    {
      "id": "391",
      "name": "Warora"
    },
    {
      "id": "392",
      "name": "Warud"
    },
    {
      "id": "393",
      "name": "Washim"
    },
    {
      "id": "394",
      "name": "Yavatmal"
    },
    {
      "id": "395",
      "name": "Yawal"
    },
    {
      "id": "396",
      "name": "Yeola"
    }
  ];
}