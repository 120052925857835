import React, { useState, useEffect } from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { statesWithCities } from '../data/location';
import { citys } from '../data/citys';

const CpaRegistrations = () => {
  const statesWithCitiesData = statesWithCities();
  const [selectedState, setSelectedState] = useState('');
  const options = citys();
  const [cities, setCities] = useState([]);
  const [captcha, setCaptcha] = useState('');
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    captcha: '',
    state: '',
    citys: '',
    class: '',
    courseInterestedIn: '',
    preferredCenter: '',
    consent: false
  });

  const [responseMessage, setResponseMessage] = useState("");

  // state select change
  const handleStateChange = (e) => {
    const stateName = e.target.value;
    setSelectedState(stateName);

    //const states = statesWithCitiesData.find(states => states.name === stateName);
    //setCities(states ? states.cities : []);

    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Generate a random CAPTCHA string
  const generateCaptcha = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    setCaptcha(result);
  };

  useEffect(() => {
    generateCaptcha(); // Generate CAPTCHA on component mount
  }, []);

  const loadCaptha = () => {
    generateCaptcha();
  }


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSelectCity = (selected) => {
    if (selected.length > 0) {
      setFormData({
        ...formData,
        ['citys']: selected[0].name,
      });
    }
  };

  const filterByStartsWith = (option, props) => {
    return option.name.toLowerCase().startsWith(props.text.toLowerCase());
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.contact) {
      newErrors.contact = 'Contact number is required';
    }
    // Check if it is a valid format (example: 10 digits)
    else if (!/^\d{10}$/.test(formData.contact)) {
      newErrors.contact = 'Contact number must be 10 digits';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    }
    else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (formData.captcha !== captcha || !formData.captcha) newErrors.captcha = 'CAPTCHA does not match. Please try again';
    if (!selectedState) newErrors.state = 'Please select state';
    if (!formData.citys) newErrors.citys = 'Please enter city';
    if (!formData.class) newErrors.class = 'Please select Class';
    if (!formData.courseInterestedIn) newErrors.courseInterestedIn = 'Please select Course';
    if (!formData.preferredCenter) newErrors.preferredCenter = 'Please select Center';
    if (formData.consent === false) newErrors.consent = 'Please tick consent checkbox to further process';
    return newErrors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      setLoader(true);
      console.log(JSON.stringify(formData));
      const response = await fetch("/payment/initiatePayment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          iid: "67108e446d8cc8622b67ca65"
        },
        body: JSON.stringify(formData),
      });
      setLoader(false);
      if (response.ok) {
        //setResponseMessage("Thank you! Your message has been sent.");
        const data = await response.json();
        setFormData({ name: "", contact: "", email: "", class: "", state: "", courseInterestedIn: "", preferredCenter: "" });
        setErrors({});
        setSuccess(true);
        window.location.href = data.payment_links.web;
        // console.log(formData);
      } else {
        setResponseMessage("There was an issue registering the CPA registration form. Please try again.");
      }
    } catch (error) {
      setLoader(false);
      setResponseMessage("Network error. Please try again.");
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div>
          <Row>
            <Col sm="6">
              <input
                type="text"
                name="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleChange}
                className='form-control'
              />
              {errors.name && <span style={{ color: 'white', fontSize: "13px" }}> * {errors.name}</span>}
            </Col>
            <Col sm="6">
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                className='form-control'
              />
              {errors.email && <span style={{ color: 'white', fontSize: "13px" }}> * {errors.email}</span>}
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col sm="6">
              <input
                type="text"
                name="contact"
                placeholder="Enter Contact Number"
                value={formData.contact}
                onChange={handleChange}
                className='form-control'
              />
              {errors.contact && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.contact}</span>}
            </Col>
            <Col sm="6">
              <select
                name="state"
                value={selectedState}
                onChange={handleStateChange}
                className='form-control'
              >
                {/* <option value="">Select a state</option> */}
                <option value="Maharashtra">Maharashtra</option>
                {/* {statesWithCitiesData.map((state) => (
                  <option key={state.name} value={state.name}>
                    {state.name}
                  </option>
                ))} */}
              </select>
              {errors.state && <span style={{ color: 'white', fontSize: "13px" }}> * {errors.state}</span>}
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col sm="6">
              {/* <input
                type="text"
                name="citys"
                placeholder="Enter Your City"
                value={formData.citys}
                onChange={handleChange}
                className='form-control'
              /> */}
              <Typeahead
                id="starts-with-search"
                labelKey="name"
                name="citys"
                onChange={handleSelectCity}
                options={options}
                placeholder="Search & select city"
                defaultValue={formData.citys}
                filterBy={filterByStartsWith}
              />
              {errors.citys && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.citys}</span>}
            </Col>
            <Col sm="6">
              <select
                name="class"
                value={formData.class}
                onChange={handleChange}
                className='form-control'
              >
                <option>Select Class Studying In</option>
                <option value="VII">VII</option>
                <option value="VIII">VIII</option>
                <option value="IX">IX</option>
                <option value="X">X</option>
                <option value="XI">XI</option>
                <option value="XII">XII</option>
              </select>
              {errors.class && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.class}</span>}
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col sm="6">
              <select
                name="courseInterestedIn"
                value={formData.courseInterestedIn}
                onChange={handleChange}
                className='form-control'
              >
                <option>Select Course Interested In</option>
                <option value="VIII">VIII Foundation</option>
                <option value="IX">IX Foundation</option>
                <option value="X">X Foundation</option>
                <option value="11 & 12 JEE">11 & 12 JEE</option>
                <option value="11 & 12 NEET">11 & 12 NEET</option>
                <option value="12th JEE">12th JEE</option>
                <option value="12th NEET">12th NEET</option>
                <option value="JEE REPEATER">JEE REPEATER</option>
                <option value="NEET REPEATER">NEET REPEATER</option>
                <option value="11 & 12 (JEE MAIN + MHT - CET)">11 & 12 (JEE MAIN + MHT - CET)</option>
                <option value="12 (JEE MAIN + MHT - CET)">12 (JEE MAIN + MHT - CET)</option>
              </select>
              {errors.courseInterestedIn && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.courseInterestedIn}</span>}
            </Col>
            <Col sm="6">

              <select
                name="preferredCenter"
                value={formData.preferredCenter}
                onChange={handleChange}
                className='form-control'
              >
                <option>Select Prefered Center</option>
                <option value="Tilak nagar">Tilak Nagar - Nagpur</option>
                {/* <option value="Traffic Park Nagpur">Traffic Park Nagpur</option> */}
                <option value="Chhatrapati Nagar - Nagpur">Chhatrapati Nagar - Nagpur</option>
                <option value="Hanuman Nagar - Nagpur">Hanuman Nagar - Nagpur</option>
                <option value="Sadar - Nagpur">Sadar - Nagpur</option>
                <option value="Residential Campus - Nagpur">Residential Campus - Nagpur</option>
                <option value="Amravati">Amravati</option>
                <option value="Chandrapur">Chandrapur</option>
                <option value="Yavatmal">Yavatmal</option>
                <option value="Nanded">Nanded</option>
                <option value="Nashik">Nashik</option>
                <option value="Sambhaji Nagar">Sambhaji Nagar</option>
              </select>
              {errors.preferredCenter && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.preferredCenter}</span>}
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col sm="6">
              <div className='captcha_div form-control'>
                <span>{captcha}</span>
                <Button onClick={loadCaptha} className='captchaRefreshBtn'>Refresh</Button>
              </div>
            </Col>
            <Col sm="6">
              <input type="text" className="form-control" name="captcha" value={formData.captcha} onChange={handleChange} placeholder="Enter text as shown" />
            </Col>
            {errors.captcha && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.captcha}</span>}
          </Row>
        </div>
        <div>
          <label className="form-check-label">
            <input type="checkbox"
              className='form-check-input'
              name="consent"
              checked={formData.consent}
              onChange={handleChange} />
            &nbsp;&nbsp;<span style={{ color: 'white', fontSize: "14px" }}>I agree to give my consent to receive updates through SMS/Email & WhatsApp*</span>
          </label>
          <br />
          {errors.consent && <span style={{ color: 'white', fontSize: "13px" }}>* {errors.consent}</span>}
        </div>


        <Button className='theme_btn d-block mx-auto mt-3' type="submit" size='lg'>
        Claim Your Spot {loader && <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />}
        </Button>
        {responseMessage && <p style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>{responseMessage}</p>}
      </Form>
    </>
  )
}

export default CpaRegistrations;